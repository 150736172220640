input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.card_disabled {
  position: relative;
  pointer-events: none;
}
.card_disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 66, 66, 0.5);
  z-index: 2;
}

@media (min-width: 500px) {
  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
  }
}

/* @media (min-width: 500px) {
  .react-tabs {
    min-width: 500px;
  }
} */

.react-tabs__tab-list {
  display: inline-flex;
  list-style-type: none;
  /* margin-top: 0; */
  padding: 2px;
  color: white;
  background-color: #514b45;
  border-radius: 5px;
  height: 24px;
  line-height: 22px;
  /* box-sizing: border-box; */
}

.react-tabs__tab {
  padding: 2px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  padding: 1px;
  background-color: #6a9cd9;
  border: 1px solid #a8cdfa;
  border-radius: 5px;
}

/* 
.react-tabs__tab {
  color: #707070;
  font-size: 1.8rem;
  cursor: pointer;
} */

/* .react-tabs__tab--selected {
  color: #f4d7a2;
} */

/* @media (max-width: 960px) {
  .react-tabs__tab {
    font-size: 1.7rem;
  }
} */

.select-grid {
  left: 50%;
  transform: translateX(-50%);
}
/* @media (max-width: 500px) {
  .select-grid {
    zoom: 0.85;
  }
}
@media (max-width: 400px) {
  .select-grid {
    zoom: 0.75;
  }
} */

.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
}
