
.mobile-nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 19%;
  height: 4rem;
  background-color: #295994;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: white;
  text-decoration: none;
}

:global(.active).mobile-nav-button {
  background-color: #3b81d6;
  padding-bottom: 2rem;
}